import isObject from '@anywr/ds/src/utils/is-object.util';

const deepMerge = <T extends Record<string, unknown>>(
  initialValue: T,
  newValue: T,
): T => {
  if (isObject(initialValue) && isObject(newValue)) {
    return {
      ...initialValue,
      ...newValue,
      ...Object
        .keys(initialValue)
        .filter((key: string) => isObject(initialValue[key]))
        .reduce(
          (nestedValue: T, key: string) => ({
            ...nestedValue,
            [key]: deepMerge(initialValue[key] as T, newValue[key] as T),
          }),
          {} as T,
        ),
    };
  }

  if (newValue !== undefined) {
    return newValue;
  }

  return initialValue;
};

export default deepMerge;
