import { createI18n } from 'vue-i18n';

import SupportedLanguages from '@anywr/ds/src/enums/supported-languages.enum';

import messagesEn from '@anywr/ds/src/locales/en.json';
import messagesFr from '@anywr/ds/src/locales/fr.json';

const instance = createI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  allowComposition: true,
  messages: {
    [SupportedLanguages.En]: messagesEn,
    [SupportedLanguages.Fr]: messagesFr,
  },
});

const loadedLanguages = ['fr'];

const setI18nLanguage = (lang: SupportedLanguages): string => {
  instance.global.locale = lang;

  const htmlTag = document.querySelector('html');

  if (htmlTag) {
    htmlTag.setAttribute('lang', lang);
  }

  return lang;
};

const loadLanguageAsync = (lang: SupportedLanguages): Promise<string> => (
  new Promise((resolve, reject) => {
    if (instance.global.locale === lang) {
      resolve(lang);
    }

    if (loadedLanguages.includes(lang)) {
      resolve(setI18nLanguage(lang));
    }

    import(/* webpackChunkName: "lang-[request]" */ `@anywr/ds/src/locales/${lang}.json`)
      .then(
        (loadedMessages) => {
          instance.global.setLocaleMessage(lang, loadedMessages.default);

          loadedLanguages.push(lang);

          resolve(setI18nLanguage(lang));
        },
      )
      .catch((err) => reject(err));
  })
);

const i18n = {
  loadLanguageAsync,
  instance,
};

export default i18n;
