import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            modelValue: _ctx.locale,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locale) = $event)),
            "hide-details": "",
            variant: "outlined",
            class: "no-label lang-switcher",
            items: _ctx.locales
          }, {
            item: _withCtx(({ item }) => [
              _createVNode(_component_v_list_item, {
                class: "text-capitalize text-14",
                onClick: ($event: any) => (_ctx.setLocale(item.value))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.value), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }, 8, ["modelValue", "items"]),
          _createVNode(_component_router_link, {
            class: "text-18 text-primary",
            to: { name: 'demo' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Demo ")
            ]),
            _: 1
          }),
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}