import { PrimeVueLocaleOptions } from 'primevue/config';

import SupportedLanguages from '@anywr/ds/src/enums/supported-languages.enum';

export default {
  firstDayOfWeek: {
    en: 0,
    fr: 1,
  },
} as Record<keyof PrimeVueLocaleOptions, Record<SupportedLanguages, number>>;
