// eslint-disable-next-line import/order
import vuetify from './plugins/vuetify';
import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import AnywrDesignSystem from '@anywr/ds/index';
import { AnywrDesignSystemOptions } from '@anywr/ds/src/types/config.type';
import 'primevue/resources/themes/aura-light-green/theme.css';

// import './scss/variables.scss';

import App from './App.vue';
import i18n from './i18n';
import router from './router';

createApp(App)
  .use(i18n.instance)
  .use(router)
  .use(vuetify)
  .use(PrimeVue)
  .use(AnywrDesignSystem, {
    i18n,
    vuetify,
  } as AnywrDesignSystemOptions)
  .mount('#app');
