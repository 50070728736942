/* eslint-disable @typescript-eslint/no-explicit-any */
const properties: Record<string, any> = {};

const setProperty = (key: string, value: any): void => {
  properties[key] = value;
};

const getProperty = (key: string): any => properties[key];

export {
  setProperty,
  getProperty,
};
