<template>
  <v-app>
    <v-main>
      <v-select
        v-model="locale"
        hide-details
        variant="outlined"
        class="no-label lang-switcher"
        :items="locales"
      >
        <template #item="{ item }">
          <v-list-item
            class="text-capitalize text-14"
            @click="setLocale(item.value)"
          >
            {{ item.value }}
          </v-list-item>
        </template>
      </v-select>

      <router-link
        class="text-18 text-primary"
        :to="{ name: 'demo' }"
      >
        Demo
      </router-link>

      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SupportedLanguages from '@anywr/ds/src/enums/supported-languages.enum';

import i18n from './i18n';

export default defineComponent({
  name: 'App',
  data: () => ({
    batata: '',
    locales: Object.values(SupportedLanguages),
  }),
  computed: {
    locale(): SupportedLanguages {
      return this.$i18n.locale as SupportedLanguages;
    },
  },
  methods: {
    setLocale(locale: SupportedLanguages): void {
      i18n.loadLanguageAsync(locale);
    },
  },
});
</script>

<style lang="scss">
@import url('~@anywr/ds/scss/fonts/index.scss');
@import "vuetify/styles";
@import '@/assets/styles';
</style>
