import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "demo" */ '@/views/Demo/index.vue'),
    children: [
      {
        path: 'base-colors',
        name: 'demo-base-colors',
        component: () => import(/* webpackChunkName: "demo-base-colors" */ '@/views/Demo/BaseColors.vue'),
      },
      {
        path: 'theme-colors',
        name: 'demo-theme-colors',
        component: () => import(/* webpackChunkName: "demo-theme-colors" */ '@/views/Demo/ThemeColors.vue'),
      },
      {
        path: 'typography',
        name: 'demo-typography',
        component: () => import(/* webpackChunkName: "demo-typography" */ '@/views/Demo/Typography.vue'),
      },
      {
        path: 'breakpoints',
        name: 'demo-breakpoints',
        component: () => import(/* webpackChunkName: "demo-breakpoints" */ '@/views/Demo/Breakpoints.vue'),
      },
      {
        path: 'icons',
        name: 'demo-icons',
        component: () => import(/* webpackChunkName: "demo-icons" */ '@/views/Demo/Icons.vue'),
      },
      {
        path: 'toast',
        name: 'demo-toast',
        component: () => import(/* webpackChunkName: "demo-toast" */ '@/views/Demo/Toast.vue'),
      },
      {
        path: 'progress-bar',
        name: 'demo-progress-bar',
        component: () => import(/* webpackChunkName: "demo-progress-bar" */ '@/views/Demo/ProgressBar.vue'),
      },
      {
        path: 'statuses',
        name: 'demo-statuses',
        component: () => import(/* webpackChunkName: "demo-statuses" */ '@/views/Demo/Statuses.vue'),
      },
    ],
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  // base: process.env.BASE_URL,
});

export default router;
