import moment from 'moment';

import dateTranslations from '@anywr/ds/src/consts/date-translations.const';
import SupportedLanguages from '@anywr/ds/src/enums/supported-languages.enum';

moment.updateLocale(SupportedLanguages.En, {
  weekdays: dateTranslations[SupportedLanguages.En].dayNames,
  months: dateTranslations[SupportedLanguages.En].monthNames,
  weekdaysMin: dateTranslations[SupportedLanguages.En].dayNamesMin,
  weekdaysShort: dateTranslations[SupportedLanguages.En].dayNamesShort,
  monthsShort: dateTranslations[SupportedLanguages.En].monthNamesShort,
  longDateFormat: {
    L: 'M/D/YYYY',
  } as moment.LongDateFormatSpec,
});

moment.updateLocale(SupportedLanguages.Fr, {
  weekdays: dateTranslations[SupportedLanguages.Fr].dayNames,
  months: dateTranslations[SupportedLanguages.Fr].monthNames,
  weekdaysMin: dateTranslations[SupportedLanguages.Fr].dayNamesMin,
  weekdaysShort: dateTranslations[SupportedLanguages.Fr].dayNamesShort,
  monthsShort: dateTranslations[SupportedLanguages.Fr].monthNamesShort,
  longDateFormat: {
    L: 'DD/MM/YYYY',
  } as moment.LongDateFormatSpec,
});
