/* eslint-disable prefer-object-spread */
import { I18n } from 'vue-i18n';
import type { LocaleMessage } from '@intlify/core-base';

import deepMerge from '@anywr/ds/src/utils/deep-merge.util';

export default (
  i18n: I18n,
  lang: string,
  localeMessages: LocaleMessage,
): void => {
  i18n
    .global
    .setLocaleMessage(lang, deepMerge(localeMessages, i18n.global.messages[lang]));
};
