import { Plugin, createApp } from 'vue';
// import Vuetify from 'vuetify/framework';
import { createVuetify } from 'vuetify';
import moment from 'moment';
import PrimeVue, { PrimeVueLocaleOptions } from 'primevue/config';
import PrimeToastService from 'primevue/toastservice';

import dateTranslations from '@anywr/ds/src/consts/date-translations.const';
import primevueNumberTranslations from '@anywr/ds/src/consts/primevue-number-translations.const';
import { ToastSeverities } from '@anywr/ds/src/enums/toast.enum';
import { Anywr, AnywrDesignSystemOptions } from '@anywr/ds/src/types/config.type';
import { ToastMessage } from '@anywr/ds/src/types/toast.type';
import addLocaleMessages from '@anywr/ds/src/utils/add-locale-messages.util';
import { setProperty } from '@anywr/ds/src/utils/global-properties-util';

import './moment';

export default {
  install(Vue, options) {
    Vue.use(PrimeVue);
    Vue.use(PrimeToastService);

    if (options?.i18n) {
      const { instance, loadLanguageAsync } = options.i18n;

      options.i18n.loadLanguageAsync = async (lang: string) => {
        const loadedLang = await loadLanguageAsync(lang);

        const loadedMessages = await import(`@anywr/ds/src/locales/${loadedLang}.json`);

        addLocaleMessages(instance, loadedLang, loadedMessages.default);

        if (Vue.config.globalProperties.$primevue) {
          Vue.config.globalProperties.$primevue.config.locale = {
            ...dateTranslations[options.i18n.instance.global.locale],
            ...options.i18n.instance.global.t('$primevue') as unknown as PrimeVueLocaleOptions,
            ...Object.keys(primevueNumberTranslations)
              .reduce((current: PrimeVueLocaleOptions, key: string) => ({
                ...current,
                [key]: primevueNumberTranslations[key][loadedLang],
              }), {} as PrimeVueLocaleOptions),
          };
        }

        return loadedLang;
      };
    }

    const vuetify = createVuetify({});

    options.vuetify.locale.t = (key, ...params) => {
      if (options.i18n.instance.global.te(key)) {
        return options.i18n.instance.global.t(key, params).toString();
      }

      return vuetify.locale.t(key, ...params);
    };

    const toastLife = 7000;

    setProperty('$appGlobalProperty', options.i18n);

    Vue.config.globalProperties.$anywr = {
      moment,
      i18n: options?.i18n.instance,
      googleMapsApiKey: options?.googleMapsApiKey,
      pushError: (message: ToastMessage) => {
        Vue.config.globalProperties.$toast.add({
          life: toastLife,
          severity: ToastSeverities.Error,
          summary: `${options.i18n.instance.global.t('message.error')}${options.i18n.instance.global.t('exclamationMark')}`,
          ...message,
        });
      },
      pushSuccess: (message: ToastMessage) => {
        Vue.config.globalProperties.$toast.add({
          life: toastLife,
          severity: ToastSeverities.Success,
          summary: `${options.i18n.instance.global.t('message.success')}${options.i18n.instance.global.t('exclamationMark')}`,
          ...message,
        });
      },
      pushInfo: (message: ToastMessage) => {
        Vue.config.globalProperties.$toast.add({
          life: toastLife,
          severity: ToastSeverities.Info,
          summary: options.i18n.instance.global.t('message.info'),
          ...message,
        });
      },
    } as Anywr;
  },
} as Plugin<AnywrDesignSystemOptions>;
